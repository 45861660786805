import FirabseService from "../../services/firebase.js"

import React from 'react';
import { logEvent, isSupported } from "firebase/analytics";
class FirebaseProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firebase: FirabseService,
      user: null
    }
  }
  componentDidMount = () => {
    //console.log("FirebaseProvider componentDidMount")

    const { firebase } = this.state;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({user: user});
      } else {
        firebase.auth().signOut()
        this.setState({user: null})        
      }
    });
  }
  render() {
    //console.log("FirebaseProvider render", this)
    const childrenWithProps = React.Children.map(this.props.children, child => {
      //console.log("child", child.props.location.href)
      //TODO: this is bad
      if (isSupported() && child.props && child.props.location) {
        setTimeout(() => {
          logEvent(this.state.firebase.analytics(), "page_view", {page_path: child.props.path, page_location: child.props.location.href})
        }, 1000);
      }
      return React.cloneElement(child, { 
        auth: this.state.firebase.auth, 
        database: this.state.firebase.database,
        analytics: this.state.firebase.analytics,
        user: this.state.user 
      })
    }
    );
    return childrenWithProps;
  }
}

export default FirebaseProvider;