import React from "react"

import FirebaseProvider from './FirebaseProvider';
const FirebaseLayout = ({ children }) => (
  <FirebaseProvider>
    {children}
  </FirebaseProvider>
)

export default FirebaseLayout
