//import * as firebase from 'firebase/app';
//import firebase from "firebase/app";

//import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";

import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

//import 'firebase/auth';


// Configure Firebase.
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

class FirebaseService {
  constructor() {
    const app = firebase.initializeApp(config);
    //console.log(config);
    this.auth = () => getAuth(app);
    //console.log("auth", this.auth());
    this.database = getDatabase(app);
    //console.log("this.db", this.database);
    this.analytics = () => getAnalytics(app)
  }
}

export default new FirebaseService();